$content-spacing: 1rem;

$shadow-lg: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

$rounded-lg: 0.5rem;

$space-1: 0.25rem;
$space-2: 0.5rem;
$space-3: 0.75rem;
$space-4: 1rem;
$space-5: 1.25rem;
$space-6: 1.5rem;

$border: 1px black solid;

.box-shadow {
  border-radius: $rounded-lg;
  border: $border;
  box-shadow: $shadow-lg;
}
